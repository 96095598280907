* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Roboto", "Segoe UI", -apple-system, BlinkMacSystemFont, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    background-color: transparent;
    overflow: hidden;
}

html {
    height: 100%;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
    width: 100%;
    height: 100%;
}

.sticky {
    position: -webkit-sticky; /* Safari & IE */
    position: sticky;
    top: 0;
}

/* Disable footnote label in markdown that says "Footnotes" */
#footnote-label {
    display: none;
}

.mapboxgl-map {
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.embedChatHtmlContent {
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    white-space: pre-line;
}

/* 
.embedMarkdownContent {
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: var(--chakra-colors-gray-800);
    font-size: 16px;
    white-space: normal;
}

.embedMarkdownContent h1 {
    font-size: 32px;
    font-weight: normal;
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #1c1c1c;
}

.embedMarkdownContent h2 {
    font-size: 28px;
    font-weight: normal;
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #1c1c1c;
}

.embedMarkdownContent h3 {
    font-size: 24px;
    font-weight: normal;
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #1c1c1c;
}

.embedMarkdownContent h4 {
    font-size: 22px;
    font-weight: normal;
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #1c1c1c;
} */

.embedHtmlContent a {
    color: #894384;
}

.embedHtmlContent ol {
    padding-left: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    border-radius: 20px;
}

.embedHtmlContent ul {
    padding-left: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    border-radius: 20px;
}
.embedHtmlContent li {
    margin-bottom: 17px;
}

.cardAspectRatio {
    aspect-ratio: 1 / 1.389;
}

/* React quill */

.ql-editor {
    min-height: 100px;
    font-size: var(--chakra-fontSizes-md);
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    width: 100%;
    height: 100%;
}

.swiper-button-prev {
    pointer-events: auto;
    color: #aa98b1;
}

.swiper-button-next {
    pointer-events: auto;
    color: #aa98b1;
}

.swiper-pagination {
    pointer-events: auto;
}

.a4PosterBody {
    background: rgb(204, 204, 204);
    width: 100%;
    height: 100%;
}

.a4Poster {
    background: white;
    width: 100%;
    height: 100%;
    max-width: 210mm;
    max-height: 297mm;
    display: block;
    margin: 0 auto;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.posterInfo {
    background: #f1f1f1;
    width: 100%;
    height: 80px;
}

@media print {
    .a4PosterBody,
    .a4Poster {
        margin: 0;
        padding: 0;
        box-shadow: none;
        width: 210mm;
        height: 297mm;
    }

    .no-print,
    no-print * {
        display: none !important;
    }
    @page {
        margin: 0mm;
    }
}

:root {
    --swiper-navigation-color: #aa98b1 !important;
    --swiper-theme-color: #aa98b1 !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 10s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.screenHeader {
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 10px;
}

.contentHeader {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 10px;
}

.contentSubHeader {
    font-weight: 700;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contentText {
    font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    white-space: pre-line;
    color: black;
    font-size: 16px;
}

.contentMeta {
    font-size: 12px;
    color: #8b8787;
    text-align: left;
}

.screenBreadcrumb {
    font-size: 12px;
    color: #8b8787;
}

.titleBase {
    font-weight: 700;
    color: white;
}

.ppTitle {
    font-weight: 800;
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #493d8a;
    text-align: center;
}

.ppHeader {
    font-size: 18px;
    color: #493d8a;
}

.social-media-icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
}

.sectionContent {
}

.sectionContentLeftSidebar {
}

.sectionContentRightSidebar {
}

.sectionContentHeader {
}

.questIcon {
    width: 100px;
    height: 100px;
}

.smallQuestIcon {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.questHeader {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
}

.questDescription {
    text-align: left;
}

.appTitle {
    font-size: 28px;
    font-weight: 500;
    margin-left: 25px;
}

.userProfile {
    margin-right: 25px;
}

.leftMenuHeader {
    margin-left: 15px;
}

.user-picture {
    border-radius: 50%;
    width: 55px;
    height: 55px;
    border: 3px solid white;
}

#map-marker1 {
    border-radius: 50%;
    border: 3px solid white;
}

#map-marker2 {
    border-radius: 50%;
    border: 3px solid white;
}

.map-container {
    width: 100%;
    height: 100%;
}

.map-info {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 20px;
    margin: 12px;
    border-radius: 40px;
}

.pointHistoryBoxParent {
    top: 83px;
    right: 5px;
    width: 400px;
    border-radius: 20px;
    border-width: 1px;
    background-color: white;
    border-color: #e2e8f0;
    height: calc(100% - 88px);
}

.circle-marker {
    cursor: pointer !important;
}

.circle-marker:hover {
    z-index: 60;
}

.circle-marker-picture {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    overflow: hidden;
    background-color: white;
    position: absolute;
    top: 3px;
    left: 9px;
}

.circle-marker-d {
    position: absolute;
    top: 0px;
    left: 0px;
}

.circle-marker-chat {
    position: relative;
    top: 3px;
    left: 9px;
}

.circle-marker-chat-bubble {
    background-color: rgb(245, 245, 245);
    border-radius: 20px;
    width: fit-content;
    padding: 5px;
}

.circle-picture {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    overflow: hidden;
    background-color: white;
}

.circle-picture-preview {
    border-radius: 50%;
    width: 88px;
    height: 88px;
    overflow: hidden;
    background-color: white;
}

.circle-picture-header {
    border-radius: 50%;
    width: 63px;
    height: 63px;
    background-color: white;
}

.circle-overview-picture {
    border-radius: 50%;
    width: 84px;
    height: 84px;
    border: 4px solid white;
    background-color: white;
}

.overviewStack {
}

.circle-overview-content {
}

.circle-list-picture {
    border-radius: 50%;
    width: 76px;
    height: 76px;
    min-width: 76px;
    min-height: 76px;
    overflow: hidden;
    border: 3px solid white;
    object-fit: cover;
}

.circle-list-picture-map {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    min-width: 44px;
    min-height: 44px;
    overflow: hidden;
    border: 1px solid white;
}

.notification-picture {
    border-radius: 50%;
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    overflow: hidden;
    border: 3px solid white;
}

.notification-picture1 {
    position: absolute;
    top: 10px;
    left: 5px;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    overflow: hidden;
    border: 3px solid white;
}

.notification-picture2 {
    position: absolute;
    top: 29px;
    left: 24px;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
    overflow: hidden;
    border: 3px solid white;
}

.user-list-picture {
    border-radius: 50%;
    width: 76px;
    height: 76px;
    overflow: hidden;
    border: 3px solid white;
    object-fit: cover;
}

.members-picture {
    border-radius: 50%;
    width: 76px;
    height: 76px;
    overflow: hidden;
    border: 3px solid white;
}

.circle-chat-picture {
    border-radius: 50%;
    width: 33px;
    height: 33px;
    min-width: 33px;
    min-height: 33px;
    overflow: hidden;
}

.circle-chat-bubble {
    background-color: rgb(245, 245, 245);
    border-radius: 10px;
    padding: 11px;
}

.text-stroke {
    -webkit-text-stroke: 1px black;
    color: white;
}

.messageInput {
}

.messageInput::-webkit-scrollbar {
    display: none;
}

.chatScrollbars {
    overflow-x: hidden;
    /* Bugfix horizontal scrollbar becomes visible */
    bottom: -1px;
}

.menuHeader {
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.pointsText {
    font-size: 30px;
    font-weight: bold;
    color: #ff8e71;
}

.circle-title {
    font-size: 14px;
    font-weight: 500;
}

.circle-title-header {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.circle-list-title {
    font-size: 16px;
}

.user-list-title {
    font-size: 16px;
}

.circle-info {
    font-size: 10px;
    color: #aaa;
}

.circle-info-header {
    font-size: 16px;
    color: #888;
}

.circle-list-info {
    font-size: 12px;
    color: #888;
}

.user-list-info {
    font-size: 12px;
    color: #888;
}

.new-badge {
    border-radius: 10px;
    background-color: #d5f4d6;
    color: #22543d;
    display: inline-block;
    vertical-align: middle;
    font-weight: 700;
    font-size: 12px;
    padding-left: 2px;
    padding-right: 5px;
}

.members-icon {
    margin-bottom: 2px;
}

.followers-icon {
}

.custom-scrollbars > div:last-child {
    opacity: 0;
    transition: opacity 200ms ease;
}

.custom-scrollbars:hover > div:last-child {
    opacity: 1;
}

.main-content {
    background-color: white;
}

.mapboxgl-canvas {
    cursor: default;
}

.cornerRadius {
    border-radius: 0px;
}

.circleSectionRegion {
    width: 400px;
    min-width: 400px;
    margin-right: 0px;
    overflow: hidden;
    background-color: white;
    padding: 10px 0px 5px 0px;
    height: 100%;
}

.circleItem {
    /* min-width: 280px; */
    height: 345px;
    border-radius: 20px;
}

.userItem {
    /* min-width: 280px; */
    height: 330px;
    border-radius: 20px;
}

.dummyCircleItem {
}

.circleOverviewSectionRegion {
    width: 500px;
    min-width: 100px;
    margin-right: 0px;
    overflow: hidden;
    background-color: white;
    height: 100%;
}

.circleSubSectionRegion {
    width: 200px;
    min-width: 200px;
    margin-right: 5px;
    overflow: hidden;
    background-color: rgb(250, 250, 250);
    padding: 20px 0px 5px 0px;
    height: 100%;
}

.circleChatSubSectionRegion {
    width: 120px;
    min-width: 120px;
    margin-right: 5px;
    overflow: hidden;
    background-color: rgb(250, 250, 250);
    padding: 20px 0px 5px 0px;
    height: 100%;
}

.circleAdminSubSectionRegion {
    width: 185px;
    min-width: 185px;
    max-width: 185px;
    margin-right: 5px;
    overflow: hidden;
    background-color: rgb(250, 250, 250);
    padding: 20px 0px 5px 0px;
    height: 100%;
}

.userSectionHeader {
    font-weight: 500;
    font-size: 28px;
    margin-bottom: 10px;
}

.circleSectionContent {
    margin-left: 20px;
    margin-right: 20px;
}

.discoverCirclesList {
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 14px;
    margin-left: 14px;
}

.strike > span {
    position: relative;
    display: inline-block;
}

.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: #e2e8f0;
}

.strike > span:before {
    right: 100%;
    margin-right: 15px;
}

.strike > span:after {
    left: 100%;
    margin-left: 15px;
}

.fixedSize {
    flex-grow: 0;
    flex-shrink: 0;
}

.notificationsBoxParent {
    top: 83px;
    right: 5px;
    width: 400px;
    border-radius: 20px;
    border-width: 1px;
    background-color: white;
    border-color: #e2e8f0;
    height: calc(100% - 88px);
}

.notificationsBox {
    border-radius: 20px;
    width: 100%;
    height: 100%;
}

.messagesBoxParent {
    top: 83px;
    right: 5px;
    width: 400px;
    border-radius: 20px;
    border-width: 1px;
    background-color: white;
    border-color: #e2e8f0;
    height: calc(100% - 88px);
}

.messagesBox {
    border-radius: 20px;
    width: 100%;
    height: 100%;
}

.circle-marker {
    cursor: pointer !important;
}

*:focus {
    box-shadow: none !important;
}

div.ReactTags__tags {
    position: relative;
}

div.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 10px;
}

input.ReactTags__tagInputField {
    width: 100%;
    outline: 2px solid transparent;
    outline-offset: 2px;
    appearance: none;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    font-size: var(--chakra-fontSizes-md);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-10);
    padding-inline-end: var(--chakra-space-10);
    height: var(--chakra-sizes-10);
    border-radius: var(--chakra-radii-md);
    border: 1px solid;
    border-color: var(--chakra-colors-gray-200);
}

input.ReactTags__tagInputField:hover {
    border-color: var(--chakra-colors-gray-300);
}

input.ReactTags__tagInputField:focus {
    border: 1px solid #3182ce;
}

div.ReactTags__selected span.ReactTags__tag {
    border: 0px solid #ddd;
    border-color: var(--chakra-colors-gray-300);
    background: #9cb5f7;
    font-size: 14px;
    display: inline-block;
    padding: 2px 10px 2px 10px;
    margin: 0px 2px 4px 2px;
    cursor: move;
    border-radius: 20px;
    color: white;
}
div.ReactTags__selected .ReactTags__remove {
    color: white;
    margin-left: 5px;
    cursor: pointer;
}

div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.no-select {
    user-select: none;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.navbutton {
    border-radius: 20px;
    font-size: 14px;
    background-color: #e2e2e2;

    /* border-radius: 20px;
    font-size: 14px;
    background-color: #262626;
    color: rgb(229 231 235); */
}

.navbutton-dark {
    border-radius: 20px;
    font-size: 14px;
    background-color: #3c3d42;
    /* background-color: #262626; when bg is black */
}

.navbutton-toggled {
    /* background-color: #325e81;
    color: rgb(212, 212, 212); */

    background-color: #d18922;
    color: rgb(255, 255, 255);
}

.navbutton-toggled-dark {
    background-color: #314b8f;
    color: rgb(212, 212, 212);
}

/* *** Markdown *** */
.markdown {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    color: #24292f;
    background-color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
}

.octicon {
    display: inline-block;
    fill: currentColor;
    vertical-align: text-bottom;
}

h1:hover .anchor .octicon-link:before,
h2:hover .anchor .octicon-link:before,
h3:hover .anchor .octicon-link:before,
h4:hover .anchor .octicon-link:before,
h5:hover .anchor .octicon-link:before,
h6:hover .anchor .octicon-link:before {
    width: 16px;
    height: 16px;
    content: " ";
    display: inline-block;
    background-color: currentColor;
    -webkit-mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
    mask-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' aria-hidden='true'><path fill-rule='evenodd' d='M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z'></path></svg>");
}

details,
figcaption,
figure {
    display: block;
}

summary {
    display: list-item;
}

[hidden] {
    display: none !important;
}

a {
    background-color: transparent;
    color: #0969da;
    text-decoration: none;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: 600;
}

dfn {
    font-style: italic;
}

.embedMarkdownContent h1 {
    margin: 0.67em 0;
    font-weight: 600;
    padding-bottom: 0.3em;
    font-size: 2em;
    /* border-bottom: 1px solid hsla(210, 18%, 87%, 1); */
}

.embedMarkdownContent h1:first-child,
.embedMarkdownContent h2:first-child,
.embedMarkdownContent h3:first-child,
.embedMarkdownContent h4:first-child {
    margin-top: 0;
}

.embedMarkdownContent mark {
    background-color: #fff8c5;
    color: #24292f;
}

.embedMarkdownContent small {
    font-size: 90%;
}

.embedMarkdownContent sub,
.embedMarkdownContent sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

.embedMarkdownContent sub {
    bottom: -0.25em;
}

.embedMarkdownContent sup {
    top: -0.5em;
}

.embedMarkdownContent img {
    border-style: none;
    max-width: 100%;
    box-sizing: content-box;
    background-color: #ffffff;
}

.embedMarkdownContent code,
.embedMarkdownContent kbd,
.embedMarkdownContent pre,
.embedMarkdownContent samp {
    font-family: monospace;
    font-size: 1em;
}

.embedMarkdownContent figure {
    margin: 1em 40px;
}

.embedMarkdownContent hr {
    box-sizing: content-box;
    overflow: hidden;
    background: transparent;
    border-bottom: 1px solid hsla(210, 18%, 87%, 1);
    height: 0.25em;
    padding: 0;
    margin: 24px 0;
    background-color: #d0d7de;
    border: 0;
}

.embedMarkdownContent input {
    font: inherit;
    margin: 0;
    overflow: visible;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

a:hover {
    text-decoration: underline;
}

::placeholder {
    color: #6e7781;
    opacity: 1;
}

hr::before {
    display: table;
    content: "";
}

hr::after {
    display: table;
    clear: both;
    content: "";
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    display: block;
    width: max-content;
    max-width: 100%;
    overflow: auto;
}

td,
th {
    padding: 0;
}

details summary {
    cursor: pointer;
}

details:not([open]) > *:not(summary) {
    display: none !important;
}

a:focus,
[role="button"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: 2px solid #0969da;
    outline-offset: -2px;
    box-shadow: none;
}

a:focus:not(:focus-visible),
[role="button"]:focus:not(:focus-visible),
input[type="radio"]:focus:not(:focus-visible),
input[type="checkbox"]:focus:not(:focus-visible) {
    outline: solid 1px transparent;
}

a:focus-visible,
[role="button"]:focus-visible,
input[type="radio"]:focus-visible,
input[type="checkbox"]:focus-visible {
    outline: 2px solid #0969da;
    outline-offset: -2px;
    box-shadow: none;
}

a:not([class]):focus,
a:not([class]):focus-visible,
input[type="radio"]:focus,
input[type="radio"]:focus-visible,
input[type="checkbox"]:focus,
input[type="checkbox"]:focus-visible {
    outline-offset: 0;
}

kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
    line-height: 10px;
    color: #24292f;
    vertical-align: middle;
    background-color: #f6f8fa;
    border: solid 1px rgba(175, 184, 193, 0.2);
    border-bottom-color: rgba(175, 184, 193, 0.2);
    border-radius: 6px;
    box-shadow: inset 0 -1px 0 rgba(175, 184, 193, 0.2);
}

.embedMarkdownContent h1,
.embedMarkdownContent h2,
.embedMarkdownContent h3,
.embedMarkdownContent h4,
.embedMarkdownContent h5,
.embedMarkdownContent h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
    line-height: 1.25;
}

.embedMarkdownContent h2 {
    font-weight: 600;
    padding-bottom: 0.3em;
    font-size: 1.5em;
    /* border-bottom: 1px solid hsla(210, 18%, 87%, 1); */
}

.embedMarkdownContent h3 {
    font-weight: 600;
    font-size: 1.25em;
}

.embedMarkdownContent h4 {
    font-weight: 600;
    font-size: 1em;
}

.embedMarkdownContent h5 {
    font-weight: 600;
    font-size: 0.875em;
}

.embedMarkdownContent h6 {
    font-weight: 600;
    font-size: 0.85em;
    color: #57606a;
}

.embedMarkdownContent p:not(:last-child) {
    margin-top: 0;
    margin-bottom: 10px;
}

.embedMarkdownContent blockquote {
    margin: 0;
    padding: 0 1em;
    color: #57606a;
    border-left: 0.25em solid #d0d7de;
}

.embedMarkdownContent ul,
.embedMarkdownContent ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 2em !important;
}

.embedMarkdownContent ol ol,
.embedMarkdownContent ul ol {
    list-style-type: lower-roman;
}

.embedMarkdownContent ul ul ol,
.embedMarkdownContent ul ol ol,
.embedMarkdownContent ol ul ol,
.embedMarkdownContent ol ol ol {
    list-style-type: lower-alpha;
}

.embedMarkdownContent dd {
    margin-left: 0;
}

.embedMarkdownContent tt,
.embedMarkdownContent code,
.embedMarkdownContent samp {
    font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
    font-size: 12px;
}

.embedMarkdownContent pre {
    margin-top: 0;
    margin-bottom: 0;
    font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
    font-size: 12px;
    word-wrap: normal;
}

.embedMarkdownContent .octicon {
    display: inline-block;
    overflow: visible !important;
    vertical-align: text-bottom;
    fill: currentColor;
}

.embedMarkdownContent input::-webkit-outer-spin-button,
.embedMarkdownContent input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
    appearance: none;
}

.markdown-body::before {
    display: table;
    content: "";
}

.markdown-body::after {
    display: table;
    clear: both;
    content: "";
}

.markdown-body > *:first-child {
    margin-top: 0 !important;
}

.markdown-body > *:last-child {
    margin-bottom: 0 !important;
}

.embedMarkdownContent a:not([href]) {
    color: inherit;
    text-decoration: none;
}

.embedMarkdownContent .absent {
    color: #cf222e;
}

.embedMarkdownContent .anchor {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
    line-height: 1;
}

.embedMarkdownContent .anchor:focus {
    outline: none;
}

.embedMarkdownContent p:not(:last-child),
.embedMarkdownContent blockquote,
.embedMarkdownContent ul,
.embedMarkdownContent ol,
.embedMarkdownContent dl,
.embedMarkdownContent table,
.embedMarkdownContent pre,
.embedMarkdownContent details {
    margin-top: 0;
    margin-bottom: 16px;
}

.embedMarkdownContent blockquote > :first-child {
    margin-top: 0;
}

.embedMarkdownContent blockquote > :last-child {
    margin-bottom: 0;
}

.embedMarkdownContent h1 .octicon-link,
.embedMarkdownContent h2 .octicon-link,
.embedMarkdownContent h3 .octicon-link,
.embedMarkdownContent h4 .octicon-link,
.embedMarkdownContent h5 .octicon-link,
.embedMarkdownContent h6 .octicon-link {
    color: #24292f;
    vertical-align: middle;
    visibility: hidden;
}

.embedMarkdownContent h1:hover .anchor,
.embedMarkdownContent h2:hover .anchor,
.embedMarkdownContent h3:hover .anchor,
.embedMarkdownContent h4:hover .anchor,
.embedMarkdownContent h5:hover .anchor,
.embedMarkdownContent h6:hover .anchor {
    text-decoration: none;
}

.embedMarkdownContent h1:hover .anchor .octicon-link,
.embedMarkdownContent h2:hover .anchor .octicon-link,
.embedMarkdownContent h3:hover .anchor .octicon-link,
.embedMarkdownContent h4:hover .anchor .octicon-link,
.embedMarkdownContent h5:hover .anchor .octicon-link,
.embedMarkdownContent h6:hover .anchor .octicon-link {
    visibility: visible;
}

.embedMarkdownContent h1 tt,
.embedMarkdownContent h1 code,
.embedMarkdownContent h2 tt,
.embedMarkdownContent h2 code,
.embedMarkdownContent h3 tt,
.embedMarkdownContent h3 code,
.embedMarkdownContent h4 tt,
.embedMarkdownContent h4 code,
.embedMarkdownContent h5 tt,
.embedMarkdownContent h5 code,
.embedMarkdownContent h6 tt,
.embedMarkdownContent h6 code {
    padding: 0 0.2em;
    font-size: inherit;
}

summary h1,
summary h2,
summary h3,
summary h4,
summary h5,
summary h6 {
    display: inline-block;
}

summary h1 .anchor,
summary h2 .anchor,
summary h3 .anchor,
summary h4 .anchor,
summary h5 .anchor,
summary h6 .anchor {
    margin-left: -40px;
}

summary h1,
summary h2 {
    padding-bottom: 0;
    border-bottom: 0;
}

.embedMarkdownContent ul.no-list,
.embedMarkdownContent ol.no-list {
    padding: 0;
    list-style-type: none;
}

.embedMarkdownContent ol[type="a"] {
    list-style-type: lower-alpha;
}

.embedMarkdownContent ol[type="A"] {
    list-style-type: upper-alpha;
}

.embedMarkdownContent ol[type="i"] {
    list-style-type: lower-roman;
}

.embedMarkdownContent ol[type="I"] {
    list-style-type: upper-roman;
}

.embedMarkdownContent ol[type="1"] {
    list-style-type: decimal;
}

.embedMarkdownContent div > ol:not([type]) {
    list-style-type: decimal;
}

.embedMarkdownContent ul ul,
.embedMarkdownContent ul ol,
.embedMarkdownContent ol ol,
.embedMarkdownContent ol ul {
    margin-top: 0;
    margin-bottom: 0;
}

.embedMarkdownContent li > p {
    margin-top: 16px;
}

.embedMarkdownContent li + li {
    margin-top: 0.25em;
}

.embedMarkdownContent dl {
    padding: 0;
}

.embedMarkdownContent dl dt {
    padding: 0;
    margin-top: 16px;
    font-size: 1em;
    font-style: italic;
    font-weight: 600;
}

.embedMarkdownContent dl dd {
    padding: 0 16px;
    margin-bottom: 16px;
}

.embedMarkdownContent table th {
    font-weight: 600;
}

.embedMarkdownContent table th,
.embedMarkdownContent table td {
    padding: 6px 13px;
    border: 1px solid #d0d7de;
}

.embedMarkdownContent table tr {
    background-color: #ffffff;
    border-top: 1px solid hsla(210, 18%, 87%, 1);
}

.embedMarkdownContent table tr:nth-child(2n) {
    background-color: #f6f8fa;
}

.embedMarkdownContent table img {
    background-color: transparent;
}

.embedMarkdownContent img[align="right"] {
    padding-left: 20px;
}

.embedMarkdownContent img[align="left"] {
    padding-right: 20px;
}

.embedMarkdownContent .emoji {
    max-width: none;
    vertical-align: text-top;
    background-color: transparent;
}

.embedMarkdownContent span.frame {
    display: block;
    overflow: hidden;
}

.embedMarkdownContent span.frame > span {
    display: block;
    float: left;
    width: auto;
    padding: 7px;
    margin: 13px 0 0;
    overflow: hidden;
    border: 1px solid #d0d7de;
}

.embedMarkdownContent span.frame span img {
    display: block;
    float: left;
}

.embedMarkdownContent span.frame span span {
    display: block;
    padding: 5px 0 0;
    clear: both;
    color: #24292f;
}

.embedMarkdownContent span.align-center {
    display: block;
    overflow: hidden;
    clear: both;
}

.embedMarkdownContent span.align-center > span {
    display: block;
    margin: 13px auto 0;
    overflow: hidden;
    text-align: center;
}

.embedMarkdownContent span.align-center span img {
    margin: 0 auto;
    text-align: center;
}

.embedMarkdownContent span.align-right {
    display: block;
    overflow: hidden;
    clear: both;
}

.embedMarkdownContent span.align-right > span {
    display: block;
    margin: 13px 0 0;
    overflow: hidden;
    text-align: right;
}

.embedMarkdownContent span.align-right span img {
    margin: 0;
    text-align: right;
}

.embedMarkdownContent span.float-left {
    display: block;
    float: left;
    margin-right: 13px;
    overflow: hidden;
}

.embedMarkdownContent span.float-left span {
    margin: 13px 0 0;
}

.embedMarkdownContent span.float-right {
    display: block;
    float: right;
    margin-left: 13px;
    overflow: hidden;
}

.embedMarkdownContent span.float-right > span {
    display: block;
    margin: 13px auto 0;
    overflow: hidden;
    text-align: right;
}

.embedMarkdownContent code,
.embedMarkdownContent tt {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    white-space: break-spaces;
    background-color: rgba(175, 184, 193, 0.2);
    border-radius: 6px;
}

.embedMarkdownContent code br,
.embedMarkdownContent tt br {
    display: none;
}

.embedMarkdownContent del code {
    text-decoration: inherit;
}

.embedMarkdownContent samp {
    font-size: 85%;
}

.embedMarkdownContent pre code {
    font-size: 100%;
}

.embedMarkdownContent pre > code {
    padding: 0;
    margin: 0;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
}

.embedMarkdownContent .highlight {
    margin-bottom: 16px;
}

.embedMarkdownContent .highlight pre {
    margin-bottom: 0;
    word-break: normal;
}

.embedMarkdownContent .highlight pre,
.embedMarkdownContent pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 6px;
}

.embedMarkdownContent pre code,
.embedMarkdownContent pre tt {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
}

.embedMarkdownContent .csv-data td,
.embedMarkdownContent .csv-data th {
    padding: 5px;
    overflow: hidden;
    font-size: 12px;
    line-height: 1;
    text-align: left;
    white-space: nowrap;
}

.embedMarkdownContent .csv-data .blob-num {
    padding: 10px 8px 9px;
    text-align: right;
    background: #ffffff;
    border: 0;
}

.embedMarkdownContent .csv-data tr {
    border-top: 0;
}

.embedMarkdownContent .csv-data th {
    font-weight: 600;
    background: #f6f8fa;
    border-top: 0;
}

[data-footnote-ref]::before {
    content: "[";
}

[data-footnote-ref]::after {
    content: "]";
}

.footnotes {
    font-size: 12px;
    color: #57606a;
    border-top: 1px solid #d0d7de;
}

.footnotes ol {
    padding-left: 16px;
}

.footnotes ol ul {
    display: inline-block;
    padding-left: 16px;
    margin-top: 16px;
}

.footnotes li {
    position: relative;
}

.footnotes li:target::before {
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -24px;
    pointer-events: none;
    content: "";
    border: 2px solid #0969da;
    border-radius: 6px;
}

.footnotes li:target {
    color: #24292f;
}

.footnotes .data-footnote-backref g-emoji {
    font-family: monospace;
}

.pl-c {
    color: #6e7781;
}

.pl-c1,
.pl-s .pl-v {
    color: #0550ae;
}

.pl-e,
.pl-en {
    color: #8250df;
}

.pl-smi,
.pl-s .pl-s1 {
    color: #24292f;
}

.pl-ent {
    color: #116329;
}

.pl-k {
    color: #cf222e;
}

.pl-s,
.pl-pds,
.pl-s .pl-pse .pl-s1,
.pl-sr,
.pl-sr .pl-cce,
.pl-sr .pl-sre,
.pl-sr .pl-sra {
    color: #0a3069;
}

.pl-v,
.pl-smw {
    color: #953800;
}

.pl-bu {
    color: #82071e;
}

.pl-ii {
    color: #f6f8fa;
    background-color: #82071e;
}

.pl-c2 {
    color: #f6f8fa;
    background-color: #cf222e;
}

.pl-sr .pl-cce {
    font-weight: bold;
    color: #116329;
}

.pl-ml {
    color: #3b2300;
}

.pl-mh,
.pl-mh .pl-en,
.pl-ms {
    font-weight: bold;
    color: #0550ae;
}

.pl-mi {
    font-style: italic;
    color: #24292f;
}

.pl-mb {
    font-weight: bold;
    color: #24292f;
}

.pl-md {
    color: #82071e;
    background-color: #ffebe9;
}

.pl-mi1 {
    color: #116329;
    background-color: #dafbe1;
}

.pl-mc {
    color: #953800;
    background-color: #ffd8b5;
}

.pl-mi2 {
    color: #eaeef2;
    background-color: #0550ae;
}

.pl-mdr {
    font-weight: bold;
    color: #8250df;
}

.pl-ba {
    color: #57606a;
}

.pl-sg {
    color: #8c959f;
}

.pl-corl {
    text-decoration: underline;
    color: #0a3069;
}

g-emoji {
    display: inline-block;
    min-width: 1ch;
    font-family: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1em;
    font-style: normal !important;
    font-weight: 400;
    line-height: 1;
    vertical-align: -0.075em;
}

g-emoji img {
    width: 1em;
    height: 1em;
}

.task-list-item {
    list-style-type: none;
}

.task-list-item label {
    font-weight: 400;
}

.task-list-item.enabled label {
    cursor: pointer;
}

.task-list-item + .task-list-item {
    margin-top: 4px;
}

.task-list-item .handle {
    display: none;
}

.task-list-item-checkbox {
    margin: 0 0.2em 0.25em -1.4em;
    vertical-align: middle;
}

.contains-task-list:dir(rtl) .task-list-item-checkbox {
    margin: 0 -1.6em 0.25em 0.2em;
}

.contains-task-list {
    position: relative;
}

.contains-task-list:hover .task-list-item-convert-container,
.contains-task-list:focus-within .task-list-item-convert-container {
    display: block;
    width: auto;
    height: 24px;
    overflow: visible;
    clip: auto;
}

::-webkit-calendar-picker-indicator {
    filter: invert(50%);
}
